<template>
    <div>
        <div id="pdfDom" class="pdf">
            <div class="pdf-title">赊购分期付款协议</div>
            <div class="pdf-no">
                合同编号【 {{ data.bill_no }} 】
            </div>
            <div class="pdf-text">
                甲方：{{ data.contract_layer_name }}
            </div>
            <div class="pdf-text">
                乙方：{{ data.real_name }}
            </div>
            <div class="pdf-text">
                身份证号：{{ data.id_card_no }}
            </div>
            <div style="height: 40px;"></div>
            <div class="pdf-text">
                <span> 根据中华人民共和国民法典相关法律法规的规定，就甲方为乙方提供服务，乙方分次向甲方支付服务费的事宜，经双方友好协商，达成如下协议：</span>
            </div>
            <div class="pdf-small-title">
                一、服务内容
            </div>
            <div class="pdf-text">
                服务项目：{{ data.product_name }}
            </div>
            <div class="pdf-text"
                style="width: 100%;;display: flex;flex-direction: row;justify-content: space-between; align-items: center;">
                <div>门店名称：{{ data.full_name }}</div>
                <div>门店地址：{{ data.entity_address }}</div>
            </div>
            <div class="pdf-small-title">
                二、费用
            </div>
            <div class="pdf-text">
                1、乙方因购买以上服务内容应向甲方支付服务费用，并申请对全部或部分服务费用分次支付。
            </div>
            <div class="pdf-text">
                2、乙方认可并同意：<span
                    style="font-weight: 600;">甲方为一家专业提供专业服务的经营性企业，本协议第一条提及的服务由甲方向乙方提供，乙方申请的赊购付款相关服务（包括订单咨询、技术支持、账户维护管理等）由甲方合作的第三方通过运营的平台向乙方提供</span>
            </div>
            <div class="pdf-text">
                3、乙方申请赊购分期支付服务费用总金额 {{ data.reality_repayment_principal }} 元，按如下约定付款日及每期付款金额（单位：元）按期支付服务费用。乙方每期付款日及每月付款金额明细如下：
            </div>
            <div style="width: 100%;;justify-self: center;">
                <base-table :columns="orderList" :data="tableData" :total="total" :loading="loading"
                    :isPaginationShow="false" wrapperHeight="calc(100% - 45px)"></base-table>
            </div>
            <div class="pdf-text" style="font-weight: 600;">
                合计赊购分期应付服务费总金额：{{ data.reality_repayment_principal }}元。期限为{{ data.total_stage_count }}个月，自{{ data.start }}到{{ data.end }}
                止（与实际赊购分期付款日期不一致的，以实际分期付款申请通过日期起算，具体以平台显示的为准，对此乙方知悉并同意）。如付款日为每月30日或31日，若遇到天数不足30天或31天的月份，付款日为应付款当月的最后一日。如果付款日遇到法定假日或公休日，付款日期不顺延。
            </div>
            <div class="pdf-text">
                4、乙方付款方式：
            </div>
            <div class="pdf-text">
                乙方的付款方式可采取以下任意一种：
            </div>
            <div class="pdf-text">
                1） 乙方在本协议所约定的<span
                    style="font-weight: 600;">付款日当日11:00前</span>，按当期应付费用总额将款项足额存入乙方在平台绑定的银行卡中，乙方授权甲方自行或通过合作的支付机构或银行在付款日划扣乙方当期的应付款项；若甲方将对乙方享有的债权转让给其他第三方的，乙方同意债权受让方有权在付款日自行或委托第三方通过支付机构或银行从乙方在平台绑定的银行卡中划扣乙方当期的应付款项。如届时乙方在平台绑定的银行卡中金额不足以支付当期应付费用的，则甲方或债权受让人有权拒绝自行或委托第三方通过支付机构或银行进行划扣，由此造成乙方逾期付款的，乙方自行承担逾期付款产生的所有责任
            </div>
            <div class="pdf-text">
                2） <span
                    style="font-weight: 600;">乙方在本协议所约定的付款日当日24:00前，</span>通过平台按期足额支付当期应付费用总额，如截至付款日24:00未完成支付，乙方构成逾期付款，乙方需承担逾期付款产生的所有责任。
            </div>
            <div class="pdf-small-title">
                三、提前付款
            </div>
            <div class="pdf-text">
                1、本协议所约定的赊购分期付款期限内，乙方可申请提前清偿剩余的未付部分或所有款项。
            </div>
            <div class="pdf-text">
                乙方申请提前付款的，应自行通过平台发起提前付款申请，申请通过后应在结清全部已到期款项后，于申请提前付款之日所在当期付款日前向甲方支付剩余乙方应付的部分或全部款项（包括当期应付的服务费、剩余分期付款期限所涉所有或部分服务费、已产生的逾期付款的违约金等应向甲方支付的款项），并向甲方支付因提前付款产生的支付手续费，该手续费金额按照
                <span style="font-weight: 600;">实际申请提前清偿款项总额的2.5%计算。</span>
            </div>
            <div class="pdf-text">
                若乙方申请提前付款通过后，但未按照申请通过后确定金额支付款项，则乙方的提前付款申请自行取消，乙方应继续按照本协议的约定继续付款。
            </div>
            <div class="pdf-small-title">
                四、声明与承诺
            </div>
            <div class="pdf-text">
                1、甲方承诺按照国家和地方关于服务质量、消费者权益保护等方面的法律法规，诚实信用地为乙方提供优质的服务。
            </div>
            <div class="pdf-text">
                1、甲方承诺按照国家和地方关于服务质量、消费者权益保护等方面的法律法规，诚实信用地为乙方提供优质的服务。
            </div>
            <div class="pdf-text">
                <span
                    style="font-weight: 600;">乙方同意并授权</span>甲方或债权受让人在本协议所约定的每月付款日，或在乙方逾期付款后任一时间点，自行或委托第三方通过支付机构或银行从乙方在平台绑定的银行卡或乙方名下的任一银行账户中划扣乙方到期应付服务费、账户管理费以及本协议所约定的手续费、违约金等。且乙方知悉前述划扣无需另行取得乙方的同意或通知乙方。
            </div>
            <div class="pdf-text" style="font-weight: 600;">
                本协议签订后，甲方依法享有收取乙方服务费的权利，乙方尚未支付的款项即为甲方享有的应收账款债权。乙方同意甲方有权转让该等应收账款债权。
            </div>
            <div class="pdf-text">
                若甲方向第三方转让本协议项下甲方对乙方享有的应收账款债权的，乙方同意甲方或甲方授权的第三方通过乙方在平台预留的手机号或电子邮箱或通过平台站内信方式向乙方发送债权转让通知，债权转让通知一经发送即视为送达。
            </div>
            <div class="pdf-text">
                乙方知晓：甲方转让该等应收账款后，受让方仍有权转让该等应收账款债权。
            </div>
            <div class="pdf-small-title">
                五、保密义务
            </div>
            <div class="pdf-text" style="font-weight: 600;">
                1、为确保乙方身份真实性以及向乙方提供更好的保障，乙方须向甲方、平台提供身份证明、联系人信息、银行卡信息等信息（简称“个人信息”，以下同），且乙方授权平台将乙方个人信息提供给第三方机构为甲方提供实名认证服务。乙方同意并授权甲方、甲方合作的第三方平台，将乙方的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途；乙方了解并同意，甲方、甲方合作的第三方，也可能通过使用或整合所收集的乙方信息，以及取得乙方授权或依据法律共享的信息，来综合判断乙方账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
            </div>
            <div class="pdf-text">
                2、甲乙双方应对本协议内容及因本协议的签署和履行而获知的各项信息保密。
            </div>
            <div class="pdf-text">
                3、甲乙双方对因本协议的履行而必须获知保密信息的雇员、代理或其他合作的第三方披露保密信息的，应确保该些人员同样遵守本协议约定之保密义务。
            </div>
            <div class="pdf-small-title">
                六、违约责任
            </div>
            <div class="pdf-text">
                1、<span class="pdf-text"
                    style="font-weight: 600;">乙方未按本协议约定完成付款义务的，每迟延一日应向甲方承担剩余服务费总额（含已到期及未到期）×0.05%的逾期违约金。且一旦乙方逾期付款，除需向甲方支付前述逾期违约金外，还应额外向甲方支付当期应付服务费金额×2%的当期逾期违约金，</span>如乙方累计多期逾期付款的，则逾期违约金按期累计。
            </div>
            <div class="pdf-text">
                2、<span
                    style="font-weight: 600;">如乙方迟延付款，</span>甲方或债权受让人有权对乙方进行逾期付款清收（清收方式包括但不限于电话通知、上门通知、发催收函、诉讼、仲裁等），并有权委托其他主体包括但不限于律师事务所、第三方机构等（简称“其他机构”）进行上述借款的违约提醒及催收工作，若委托其他主体则甲方或债权受让人有权将乙方个人信息、联系方式、地址提供给其他机构。
                <span
                    style="font-weight: 600;">乙方延迟还款且经甲方或债权受让人提醒仍未还款超过3日的，甲方或债权受让方有权自主或通过第三方将乙方违约信用记录上传至平台、芝麻信用、民间征信机构或银行征信系统。</span>
            </div>
            <div class="pdf-text">
                3、如乙方出现以下一项或多项情况时，则视为乙方严重违约，甲方或债权受让人有权要求乙方立即提前清偿所有款项（包括已到期、未到期的服务费及违约金及提前清偿应支付的手续费（手续费=提前清偿应支付款项*2.5%）等）：
            </div>
            <div class="pdf-text">
                3.1 乙方累计逾期付款三期以上的，包含三期；
            </div>
            <div class="pdf-text">
                3.2 乙方提供虚假信息或隐瞒重要事实的；
            </div>
            <div class="pdf-text">
                3.3 乙方逃避、拒绝与甲方或债权受让人进行沟通或拒绝承认欠款事实；
            </div>
            <div class="pdf-text">
                3.4 其他对甲方应收账款产生严重不利影响的事件或行为。
            </div>
            <div class="pdf-text">
                4、除本协议另有约定外，甲乙任何一方违反本协议，应赔偿因违约而给对方造成的全部损失，包括但不限于调查费、诉讼费、律师费等。
            </div>
            <div class="pdf-small-title">
                七、通知与送达
            </div>
            <div class="pdf-text" style="margin-bottom: 20px;">
                1.1 如发送给甲方：
            </div>
            <div class="pdf-text"
                style="width: 100%;;display: flex;flex-direction: row;justify-content: space-between; align-items: center; font-weight: 600;">
                <div>联系人：{{ data.contract_layer_name }}</div>
                <div>地址：{{ data.entity_address }}</div>
            </div>
            <div class="pdf-text"
                style="width: 100%;;display: flex;flex-direction: row;justify-content: space-between; align-items: center;font-weight: 600;">
                <div>电话：{{ data.entity_phone }}</div>
                <div>电子邮箱：{{ data.entity_email }}</div>
            </div>
            <div class="pdf-text">
                1.2 如发送给乙方：
            </div>
            <div class="pdf-text"
                style="width: 100%;;display: flex;flex-direction: row;justify-content: space-between; align-items: center;font-weight: 600;">
                <div>联系人：{{ data.real_name }}</div>
                <div>地址：{{ data.user_address }}</div>
            </div>
            <div class="pdf-text"
                style="width: 100%;;display: flex;flex-direction: row;justify-content: space-between; align-items: center;font-weight: 600;">
                <div>电话：{{ data.mobile }}</div>
                <div>电子邮箱：{{ data.email }}</div>
            </div>
            <div class="pdf-text">
                2、本协议项下的通知、要求、本协议所涉之应收账款清收、诉讼（仲裁）（包括一审、二审、再审和执行程序）的法律文书或其他通信可按照上述约定的地址或联系方式交付或发送至接收方。
            </div>
            <div class="pdf-text">
                3、就本协议项下一方给予另一方的任何通知、要求、应收账款催收函或其他通信，一方可通过本协议提及的联系方式或甲乙双方在平台预留的联系方式向对方发送，其中电话、电子邮件等一经发出即视为已送达接收方；邮政信函于投寄之日起第三日即视为已送达接收方；如派人专程送达，则接收方签收日视为送达，接收方拒收的，送达人可采取拍照、录像方式记录送达过程，并将文书留置，亦视为送达。
            </div>
            <div class="pdf-text">
                4、如果任何一方提供的上述联系方式发生变更的，应在变更后三日内书面通知对方；在本协议项下应收账款进入诉讼或仲裁阶段后，则须以书面方式告知审理机关。否则按原联系方式发出的通知或其他文书，即使变更方没有收到，仍视为送达。
            </div>
            <div class="pdf-small-title">
                八、其他
            </div>
            <div class="pdf-text">
                1、甲、乙双方就本协议事项进行的任何其他口头或书面约定，与本协议不一致的以本协议为准。
            </div>
            <div class="pdf-text">
                2、因本协议引起的或与本协议有关的一切争议，甲乙双方首先应当通过友好协商解决；未能协商解决的，交由甲方所在地或乙方所在地有管辖权的人民法院诉讼解决；如发生债权转让，任何一方均有权向债权受让人所在地人民法院起诉，多次债权转让的，任何一方均有权向债权最终受让人所在地人民法院起诉。
            </div>
            <div class="pdf-text">
                3、如因乙方逾期还款或发生本合同约定的其他违约行为导致甲方或债权受让人提起诉讼，且诉讼标的额在5万元以内（含）（如诉讼管辖地区相关文件规定的适用小额诉讼程序标准低于该标准的，则以相关文件规定为准）的，双方同意依法优先适用小额诉讼程序审理。双方均确认在本协议签订前已对小额诉讼程序的审判组织、一审终审、审理方式、审理期限、举证期限、诉讼费用交纳标准等相关事项进行充分了解，明确知晓小额诉讼程序为一审终审；由审判员一人独任审理；人民法院可以通过简便方式传唤当事人、送达诉讼文书和审理案件；举证期限一般不超过七日；人民法院作出的管辖权异议裁定和驳回起诉裁定，一经作出即刻生效；适用小额诉讼程序的案件一般在立案之日起两个月内审结，在特殊情况下经审理法院院长批准可以延长一个月等。
            </div>
            <div class="pdf-text">
                4、乙方同意，本协议自双方通过线上签署后生效。
            </div>
            <div class="pdf-text">
                5、<span style="font-weight: 600;">本协议中涉及乙方的义务和责任之条款均已经以醒目的方式对乙方予以提示，乙方亦明确各条款之事项并予以接受。</span>
            </div>
            <div class="pdf-text">（以下无正文）</div>
            <div style="width:100%;display:flex;flex-direction: row;justify-content: space-between; align-items: center;">
                <div style="display: flex;flex-direction: column;">
                    <div class="pdf-text">甲方：{{ data.contract_layer_name }}</div>
                    <div class="pdf-text" style="margin-left: 60px;">{{ data.create_time }}</div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="pdf-text">乙方：{{ data.real_name }}</div>
                    <div class="pdf-text" style="margin-left: 60px;">{{ data.create_time }}</div>
                </div>
            </div>
            <div
                style="width:100%;display:flex;flex-direction: row;justify-content: space-between; align-items: center;margin-top: -95px;">
                <el-image v-if="data.canvas1" style="width: 150px;height: 150px;margin-left: 60px" :src="data.canvas1"></el-image>
                <el-image v-if="data.canvas2" style="width: 150px;height: 150px;" :src="data.canvas2"></el-image>
            </div>
        </div>
        <el-button type="text" size="small" @click="prewviewDetail()" style="align-self: center;width: 100%;">导出合同</el-button>
    </div>
</template>
<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import baseTable from "../../components/base-table/base-table.vue";
import columns from "./columns";
export default {
    components: { baseTable },
    name: 'prewviewDetail',
    mounted() {
        if (this.$route.query.data) {
            console.log(JSON.parse(decodeURIComponent(this.$route.query.data)))
            this.data = JSON.parse(decodeURIComponent(this.$route.query.data))
            this.tableData = this.data.list
            this.total = 12
            console.log(this.data.canvas1)
            // document.querySelector("img1").src = this.data.canvas1
            // document.querySelector("img2").src = this.data.canvas2
        }
    },
    data() {
        return {
            loading: false,
            ...columns,
            data: {},
            tableData: [],
        }
    },
    methods: {
        initData() {
            this.tableData = this.data.list
            this.total = this.data.list.length()
        },
        prewviewDetail() {
            let that = this
            html2Canvas(document.querySelector('#pdfDom'), {
                allowTaint: true
            }).then(function (canvas) {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 860
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(that.data.real_name + '.pdf')
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 70px;

    &-title {
        align-self: center;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.5;

    }

    &-small-title {
        font-size: 21px;
        font-weight: 600;
        line-height: 1.5;

    }

    &-no {
        align-self: flex-end;
        font-size: 21px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    &-text {
        font-size: 19px;
        font-weight: 400;
        line-height: 2;
        margin-bottom: 10px;
    }
}
</style>